

.light-container{
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/open_light_bg2.webp");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: scroll;
    background-attachment: local;
}

.title {
    margin-top: 10px;
    color: white;
    width: 355px;
    font-size: 16px;
    text-align: left;
    display: block;

}

.font_title_1 {
    width: 355px;
    font-weight: 700;
    color: white;
    font-size: 24px;
    font-weight: 700;
    text-align: left;

}

.title1 {
    margin: 20px 0px;
    color: white;
    font-size: 17px;
}

.navigator-arrow {
    padding-right: 13px;
    position: relative;
}

.navigator-arrow:after {
    content: " ";
    display: inline-block;
    height: 9px;
    width: 9px;
    border-width: 1px 1px 0 0;
    border-color: #888888;
    border-style: solid;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 14px;
}

.input-area {
    background-color: white;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    color: black;
    overflow: hidden;
    white-space: nowrap;
}

.input-area-birthday {
    background-color: white;
    padding: 0;
    font-weight: normal;
    font-size: 10px;
    color: black;
    overflow: hidden;
    white-space: nowrap;
}


.submitButton {
    margin: 30px 20px;
    line-height: 48px;
    height: 48px;
    border: 1px solid rgba(147, 143, 153, 1);
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);

}


.chinese-style-frame-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 355px;
    height: 563px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    background-color: white;

}

.chinese-style-frame {
    margin-top: 9px;
    width: 337px;
    height: 545px;
    background-image: url("./bg_light.svg");
    background-size: cover; /* 覆盖整个元素，不保留宽高比 */
    background-repeat: no-repeat; /* 不重复背景图像 */
    background-position: center; /* 将背景图像置于元素中心 */
}

.light-row_layout {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;;
    align-items: center;

}

.v-line {
    height: 34px;
    width: 1px;
    background-color: rgba(191, 106, 2, 1);
    /*margin: 0 10px;*/
}

.circled-text {
    width: 34px;
    height: 34px;
    line-height: 34px;
    display: inline-block;
    text-align: center;
    color: rgba(191, 106, 2, 1); /* 设置字体颜色 */
    font-size: 24px; /* 设置字体大小 */
    font-weight: 510; /* 设置字体粗细 */
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 50%; /* 设置圆圈的边框半径 */
    /*padding: 3px 6px; !* 设置内边距 *!*/
}

.light-title2 {
    margin: 20px 0px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.circled-img {
    border: 1px solid black;
    border-radius: 10px;
    height: 150px;
    width: 150px;
    margin: auto;
}

.dash-line {
    border: 1px dashed rgba(191, 106, 2, 1);
    margin: 10px 20px;
}

.light-info {
    font-size: 14px;
    text-align: left;
    margin: 10px 20px;
    font-weight: 400;
    white-space: nowrap;
}

.light-btn-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    /*background-color: rebeccapurple;*/
}

.light-active-btn {
    flex:1;
    background-color: rgba(179, 38, 30, 1);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 20px 20px;
    line-height: 48px;
    height: 48px;
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    color: white;

}

.light-active-btn:disabled {
    background-color: blue;;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 30px 20px;
    line-height: 48px;
    height: 48px;
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    color: white;

}

.nut-cell-title{
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}


.light-video-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 80vh;
}

