.main-visual {
    /*height: 80vh;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    color: #000000;
    position: relative;
    /*background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/oplight_bg1.webp");*/
    /*overflow: hidden;*/

}


.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
    overflow: hidden;
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/oplight_bg1.webp");
    background-size: cover; /* 覆盖整个元素，不保留宽高比 */
    background-repeat: no-repeat; /* 不重复背景图像 */
    background-position: center;
}

.video-background2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
    overflow: hidden;
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/open_light_bg2.webp");
    background-size: cover; /* 覆盖整个元素，不保留宽高比 */
    background-repeat: no-repeat; /* 不重复背景图像 */
    background-position: center; /* 将背景图像置于元素中
}

.full-page {
    max-width: 100%;
    max-height: 100%;
}

.video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    z-index: -99;
    object-fit: cover; /* 使用 cover 以填满整个容器 */
}

/*.luck-container {*/
/*    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/open_light_bg2.webp");*/
/*    background-size: cover;*/

/*    overflow: scroll;*/
/*    background-attachment: local;*/
/*}*/


.overlay {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    position: relative;
    z-index: 1;
}


.luck-title {
    color: #333;
    margin: 0 0 20px;
    font-size: 2rem;
}

.row-wrapper {
    background-color: #DCD2CC;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 15px;
    margin: 0 0 20px;

}

/*.row-wrapper-content {*/
/*    background-color: #FFFFFF99;*/
/*    font-weight: bold;*/
/*    color: black;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: 15px;*/
/*    margin: 10px 0;*/
/*    padding: 5px 10px;*/
/*    border-radius: 5px;*/
/*    background-color: rgba(255, 255, 255, 0.6);*/
/*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/

/*}*/

.show-form-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #f97316;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.nut-cell-group {
    background-color: transparent;
}

.nut-cell-group-wrap {
    background-color: transparent;
}

.nut-cell {
    background-color: transparent;


}

.nut-cell-value {
    border-radius: 0;
}

.nut-form-item-body {
    border-radius: 0;

}

.nut-form-item {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    /*align-items: center;*/
}

.nut-cell-title {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}

.nut-form-item-label {

}

.nut-radio-label {
    font-size: 16px;
}

.nut-input-native {
    background-color: white;
}

.nut-input {
    background-color: white;
}

.luck-input-item{
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    align-items: center;
    padding-left: 8px;
    height: 48px;
    font-size: 16px;

}

.luck-input-area {
    background-color: white;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    color: black;
    overflow: hidden;
    white-space: nowrap;
    border: none;
    text-align: left;
    border: none;
}

.luck-navigator-arrow {
    padding-right: 13px;
    position: relative;
}

.luck-navigator-arrow:after {
    content: " ";
    display: inline-block;
    height: 9px;
    width: 9px;
    border-width: 1px 1px 0 0;
    border-color: #888888;
    border-style: solid;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 14px;
}

.nut-radiogroup {
    display: flex;
    flex-direction: row;
}

.chart {
    width: 300px;
    height: 300px;
    margin:0 auto;
}

.font-bold {
    font-weight: bold;
    font-size: 18px;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.1);
}

.row-react {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: stretch;
}

.column-react {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: stretch;
}

.submit-button {
    background-color: #f97316;
    fontSize: 24;
    font-weight: bold;
    padding: 16px 32px;
}

.button-report {
    border: none;
    width: 190px;
    background-color: #f97316;
    color: #ffffff;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: inline-block;
    margin: 0 auto;
}

.button-wealth-secret {
    border: none;
    width: 190px;
    background-color: #4CAF50;
    color: #ffffff;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: inline-block;
    margin: 0 auto;
}

/*.nut-button-wrap {*/
/*    display: inline-block;*/
/*    margin: 0 auto;*/
/*    background-color: #f97316;*/

/*}*/

.nut-button-primary {
    background-color: #f97316;
}

.text-wuxing-summary {
    color: #dc2626;
    font-size: 20px;
    font-weight: bold;
    margin: 4px 20px;
}

.text-wuxing-buy {
    /*background-color:#f97316 ;*/
    margin-top: 40px;
    margin-bottom: 40px;
    color: black;
    font-size: 24px;
    font-weight: bold;
    margin: 4px 20px;
}


.seperator {
    border-top: 2px solid #dcdcdc;
    margin-top: 60px;
    margin-bottom: 60px;
}

.outer-container {
    position: relative;
    overflow: hidden;
    margin-top: 48px;
}

.ability_container {

    border: 2px solid rgba(255, 255, 255, 0.6);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
}

.pray_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ability_title {
    font-size: 36px;
    font-weight: 500;
    color: #000;
}

.ability_item {
    border: 2px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    width: calc(30%);
    border-radius: 8px;
    color: rgba(151, 81, 2, 1);
    font-size: 33px;
    height: 64px;
    font-weight: 500;
    text-align: center;
    line-height: 64px;
    background-color: rgba(247, 247, 247, 1);
    margin: 10px 5px;
}

.ability_container_bottom {
    height: 80px;
}

.h_line {
    background-color: rgba(151, 151, 151, 0.2);
    height: 1px;
    width: 100%;
    margin: 10px 5px;
}

.form-container{
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/oplight_bg1.webp");
}

.page-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.birthday_row {
    display: flex;
    flex-direction: row;
    height: 48px;
    background-color: rebeccapurple;
}

.spacehold {
    flex: 1;
}

.luck-birthday{
    white-space: nowrap;
    width: 48px;
}
.luck-navigator-arrow {
    padding-right: 13px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    height: 48px;
    font-size: 16px;
}

.navigator-arrow:after {
    content: " ";
    display: inline-block;
    height: 9px;
    width: 9px;
    border-width: 1px 1px 0 0;
    border-color: #888888;
    border-style: solid;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 14px;
}

.textarea {
    position: relative;
    top: 0;
    width: auto;
    height: 60px;
    /* background-color: red; */
}

.luckSubmitButton {
    text-align: center;
    margin: 10px 0;
    width: 100%;
    color: white;
    background-color: rgba(179, 38, 30, 1);
    line-height: 48px;
    height: 48px;
}

.luckSubmitButtonDisable {
    background-color: rgba(147, 143, 153, 1); /* 禁用状态的按钮颜色 */
    margin: 10px 0;
    width: 100%;
    color: white;
    text-align: center;
    line-height: 48px;
    height: 48px;
}




/**index.wxss**/

page {
    /* padding-top: 54px; */
    padding-bottom: 60px;
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/oplight_bg1.webp");

    height: 100%;
}

.container {
    width: 100%;
    height: 100%;
    align-items: center;
}

.main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: PingFang SC;
}

.main-title {
    font-weight: 500;
    color: white;
    margin-top: 40px;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
    width: 90%;
}

.luck-title {
    margin-bottom: 8px;
    margin-top: 30px;
    font-size: 16px;
    color: white;
    width: 90%;
    text-align: left;
    /*background-color: #00b578;*/
}
.form-title {
    display: block;
    margin-bottom: 8px;
    margin-top: 30px;
    /*margin-left: 40px;*/
    text-align: left;
    font-size: 16px;
    color: white;
    min-width: 90%;
}

.font_title_1 {
    font-weight: 500;
    color: white;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    /* text-align: center; */
    width: 90%;
}
.form-font_title_1 {
    font-weight: 500;
    color: white;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    /* text-align: center; */
    width: 90%;
}

.luck_font_title_1 {
    font-weight: 500;
    color: white;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
     text-align: left;
    width: 90%;
    /*background-color: #d2a448;*/
}



/* .seperator {
  border-top: 2px solid #dcdcdc;
  margin-top: 60px;
  margin-bottom: 60px;
} */

.ability_container_bottom {
    height: 40px;
}

.h_line {
    background-color: rgba(151, 151, 151, 0.2);
    height: 1px;
    width: 100%;
    margin: 5px 2px;
}

.page-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.spacehold {
    flex: 1;
}

.score-container-outer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 20px  auto ; */
    width: 355px;
    height: 388px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    background-color: white ;
}

.score-container {
    position: relative;
    display: flex;
    margin: 10px auto;
    /* position: relative; */
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    width: 337px;
    height: 368px;
    background-size: contain;
    /* background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/bg_light.svg"); */
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/bg_light368.svg");

}

.score-content {
    position: relative;
    top: 80px;
    width: 200px;
    height: 150px;
    /* background-color: gray; */
    overflow: hidden;
}

.score-image-bg {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    width: 170px;
    /* 设置宽度为自动，以保持原始宽度 */
    height: 170px;
    margin-left: -85px;
    object-fit: contain;

}

.score-image {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    transform: rotate(-45deg);
    width: 170px;
    /* 设置宽度为自动，以保持原始宽度 */
    height: 170px;
    margin-left: -85px;
    object-fit: contain;
}

.score-text {
    font-size: 66px;
    color: rgba(191, 106, 2, 1);
    font-weight: 700;
    position: absolute;
    bottom: 50px;
    width: 100px;
    margin-left: -50px;
    text-align: center;
    left: 50%;
    z-index: 3;
}

.score-label {
    font-size: 16px;
    color: rgba(153, 153, 153, 1);
    position: absolute;
    bottom: 40px;
    width: 100px;
    margin-left: -50px;
    text-align: center;
    left: 50%;
    z-index: 5;
}

.score-mask {
    position: absolute;
    width: 100%;
    height: 50px;
    z-index: 4;
    bottom: 0;
    background-color: white;
}


.row_layout {
    position: absolute;
    /* margin: 20px; */
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* background-color: blue; */

}

.v-line {
    height: 34px;
    width: 1px;
    background-color: rgba(191, 106, 2, 1);
    margin: 0 10px;
}

.circled-text {
    width: 34px;
    height: 34px;
    display: inline-block;
    color: rgba(191, 106, 2, 1);
    /* 设置字体颜色 */
    font-size: 24px;
    /* 设置字体大小 */
    font-weight: 510;
    /* 设置字体粗细 */
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 50%;
    /* 设置圆圈的边框半径 */
    /*padding: 3px 6px; !* 设置内边距 *!*/
    text-align: center;
}


.luck-line{
    background-color: rgba(191, 106, 2, 1);
    height: 1px;
    width: 100px;
}

.row-wrapper-content {
    border-top: 1px dotted rgba(191, 106, 2, 1);
    /* background-color: #FFFFFF99; */
    font-weight: 400;
    color: black;
    display: flex;
    flex-direction: column;
    /* align-items: ; */
    /* justify-content: center; */
    font-size: 16px;
    /* margin: 10px 0; */
    /* padding: 5px 10px; */
    /* border-radius: 5px; */
    /* background-color: red; */
    padding-left: 4px;
    width: 305px;
    text-align: left;
    line-height: 36px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */

}
.luck-container{
    position: absolute;
    bottom: 15px;

}



.wuxing-container-outer {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 20px  auto ; */
    width: 355px;
    height: 573px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    background-color: white ;
}

.wuxing-container {
    display: flex;
    margin: 10px auto;
    /* position: relative; */
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    width: 337px;
    height: 545px;
    background-repeat: no-repeat; /* 不重复背景图像 */
    background-position: center; /* 将背景图像置于元素中心 */
    background-size: cover;
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/bg_light.svg");

}


.wuxing_row_layout {
    /* margin: 20px; */
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* background-color: blue; */
}

.pie_container{
    /* position: relative; */
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 200px;
    height: 200px;
    /*z-index: 10;*/
    /* background-color: red; */
    overflow: hidden;
}


.wuxing-percent-row{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
}

.wuxing-percent-item{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
}

.wuxing-percent-symbol{
    color:#86909C;
    font-size: 7px;
}


.goods-suggest{
    margin-top: 10px;
    margin-bottom: 10px;
    min-height:50px;
    /* width: 100%; */
    object-fit: cover;
}

.goods-row{
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
}


.goods-item{
    width: 45%;
    /* padding: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    padding: 8px;
    /*background-color: #00b578;*/
    margin:8px;
}

.goods-img{
    width: 150px;
    height:150px;
    object-fit: cover;
}

.goods-text{
    font-weight: 400;
    font-size: 14px;
    /*height: 22px;*/
    /*line-height: 22px;*/
    width: 100%;
    color:black;
    margin-top: 10px;
    white-space: nowrap; /* 确保所有文本都在同一行内 */
    overflow: hidden; /* 隐藏超出元素盒子的内容 */
    text-overflow: ellipsis; /* 使用省略号表示超出部分 */

}

.bottom-space{
    min-height: 40px;
    width: 1px;
}
