.check-container{
    display: flex;
    /*justify-content: center;*/
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/open_light_bg3.webp");
}

.check-title {
    margin-top: 48px;
    font-weight: 700;
    color: white;
    font-size: 24px;
    font-weight: 700;

}

.check-card-container{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 355px;
    height: 220px;
    margin-top: 51px;
    border-radius: 10px;
    border: 1px solid rgba(147, 143, 153, 1);
    align-items: center;


}
.space{
    flex:1;
}

.check-light-btn {
   /*width:100%;*/
    width: 300px;
    background-color: rgba(179, 38, 30, 1);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 30px 20px;
    line-height: 48px;
    height: 48px;
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    color: white;

}



