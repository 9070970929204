
.blur-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: scroll;

}

.blur-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://ssswork.oss-cn-hangzhou.aliyuncs.com/pic/backgroud1.png");
    filter: blur(8px); /* 调整模糊程度 */
    z-index: 1;
}

.blur-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5); /* 可选的，如果需要可以添加半透明覆盖层 */
    z-index: 2;
}

.blur-content {
    position: relative;
    z-index: 3; /* 确保内容在最上层 */
    padding: 20px;
    text-align: center;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
