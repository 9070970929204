

/* 媒体查询：为特定宽度的设备设置样式 */
@media (min-width: 390px) and (max-width: 415px) {
  /* 假设iPhone 15的宽度在390px到415px之间 */
  .container {
    width: 90%; /* 在这个宽度范围内，容器宽度设为90% */
    /* 可以添加更多特定于这个宽度的样式 */
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/* 当屏幕尺寸小于或等于600px时 */
/* 当屏幕尺寸小于或等于600px时 */
@media screen and (max-width: 600px) {
  video {
    object-fit: cover;
  }
}
