
.title {
    margin-top: 10px;
    color: white;
    width: 355px;
    font-size: 16px;
    text-align: left;
    display: block;

}


.title1 {
    margin: 20px 0px;
    color: white;
    font-size: 17px;
}

.navigator-arrow {
    padding-right: 13px;
    position: relative;
}

.navigator-arrow:after {
    content: " ";
    display: inline-block;
    height: 9px;
    width: 9px;
    border-width: 1px 1px 0 0;
    border-color: #888888;
    border-style: solid;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 14px;
}

.input-area {
    background-color: white;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    color: black;
    overflow: hidden;
    white-space: nowrap;
}

.pay-phone{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    padding-left: 10px;
}

.input-area-birthday {
    background-color: white;
    padding: 0;
    font-weight: normal;
    font-size: 10px;
    color: black;
    overflow: hidden;
    white-space: nowrap;
}


.submitButton {
    margin: 30px 20px;
    line-height: 48px;
    height: 48px;
    border: 1px solid rgba(147, 143, 153, 1);
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);

}


.pray-chinese-style-frame-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 355px;
    height: 388px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    background-color: white;

}

.pray-chinese-style-frame {
    margin-top: 9px;
    width: 337px;
    height: 368px;
    background-image: url("./bg_light368.svg");
    background-size: cover; /* 覆盖整个元素，不保留宽高比 */
    background-repeat: no-repeat; /* 不重复背景图像 */
    background-position: center; /* 将背景图像置于元素中心 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
}

.share-bg {
    position:relative;
    width:100vw;
    height: 100vh;
    /*background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/share.webp");*/
    background-size: cover; /* 覆盖整个元素，不保留宽高比 */
    background-repeat: no-repeat; /* 不重复背景图像 */
    background-position: center; /* 将背景图像置于元素中心 */
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    padding-top: 180px;
    z-index: 2;
}
.share-img{
    position: absolute;
    top:0;
    width:100vw;
    height: 100vh;
}
.share-img-title{
    width:335px;
    height: 34px;
}

.row_layout {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;;
    align-items: center;

}

.v-line {
    height: 34px;
    width: 1px;
    background-color: rgba(191, 106, 2, 1);
    /*margin: 0 10px;*/
}

.circled-text {
    width: 34px;
    height: 34px;
    display: inline-block;
    text-align: center;
    color: rgba(191, 106, 2, 1); /* 设置字体颜色 */
    font-size: 24px; /* 设置字体大小 */
    font-weight: 510; /* 设置字体粗细 */
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 50%; /* 设置圆圈的边框半径 */
    /*padding: 3px 6px; !* 设置内边距 *!*/
}

.title2 {
    margin: 20px 0px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.circled-img {
    border: 1px solid black;
    border-radius: 10px;
    height: 150px;
    width: 150px;
    margin: auto;
}

.dash-line {
    border: 1px dashed rgba(191, 106, 2, 1);
    margin: 10px 20px;
}

.info {
    font-size: 16px;
    text-align: left;
    margin: 10px 20px;
    font-weight: 400;
}

.active-btn {
    background-color: rgba(179, 38, 30, 1);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 30px 20px;
    line-height: 48px;
    height: 48px;
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    color: white;

}

.active-btn:disabled {
    background-color: blue;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 30px 20px;
    line-height: 48px;
    height: 48px;
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    color: white;

}

.nut-cell-title {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}


.pray-enter-container {
    width: 100%;
    height: 100%;
    align-items: center;
    background-size: cover; /* 覆盖整个元素，不保留宽高比 */
    background-repeat: no-repeat; /* 不重复背景图像 */
    background-position: center; /* 将背景图像置于元素中心 */
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/oplight_bg1.webp");
}

.pray-container {
    background-size: cover; /* 覆盖整个元素，不保留宽高比 */
    background-repeat: no-repeat; /* 不重复背景图像 */
    background-position: center; /* 将背景图像置于元素中心 */
    width: 100vw; /* 视口宽度的100% */
    height: 100vh; /* 视口高度的100% */
    align-items: center;
    display: flex;
    justify-content: center;
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/open_light_bg2.webp");

}

.pray-main {

    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: PingFang SC;
}

.image_container {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.title {
    margin-bottom: 10px;
    margin-top: 30px;
}

.sub_title {
    font-size: 16px;
    color: black;
    font-weight: 500;
    text-align: center;
}


/* 一级标题字体 */
.font_title_1 {
    font-weight: 500;
    color: white;
    font-size: 30px;
    font-weight: 400;
    text-align: center;

}

/* 二级标题字体 */
.font_title_2 {
    color: white;
    font-size: 16px;
    font-weight: 500;
    font-family: "PingFang SC";
}

/* 内容字体 */
.font_content {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 26px;
}

.seperator {
    border-top: 1px solid #dcdcdc;
    margin-top: 30px;
    margin-bottom: 30px;
}

.outer-container {
    position: relative;
    overflow: hidden;
    margin-top: 24px;
}

.blur-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    background-size: cover;
    filter: blur(8px) brightness(0.9);
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
}

.ability_container {
    position: relative;
    z-index: 2;
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    /* background-color: rgba(255, 255, 255, 0.6); */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.ability_title {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.ability_item {
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 20px;
    color: white;
    font-size: 16px;
    height: 56px;
    text-align: center;
    line-height: 56px;
    background-color: rgba(179, 38, 30, 1);
    width: 100%;
}


.pray-ability_item {
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    width: calc(30%);
    border-radius: 4px;
    /*margin-top: 20px;*/
    color: rgba(151, 81, 2, 1);
    font-size: 16px;
    height: 32px;
    font-weight: 500;
    text-align: center;
    line-height: 32px;
    background-color: rgba(247, 247, 247, 1);
    margin: 5px 4px;
}

.ability_container_bottom {
    height: 40px;
}


.image_container {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.title {
    margin-bottom: 8px;
    margin-top: 30px;
    font-size: 31px;
    color: white;
}

.input-pray {
    placeholder-color: rgba(0, 0, 0, 0.3);
}

.textarea-container {
    width: 100%; /* 宽度100%，根据容器自适应 */
    height: 90px; /* 高度自适应 */
    line-height: 1.5; /* 行高 */
    placeholder-color: rgba(0, 0, 0, 0.3);
    padding: 10px; /* 内边距 */
    box-sizing: border-box; /* 盒模型 */
}


.pray-pay-container{
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/open_light_bg3.webp");
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: scroll;
    background-attachment: local;
    height: 100vh;
    width: 100vw;
}


.share-row_layout {

    margin:30px 10px;
    /*margin-top: 180px;*/
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

.pray-row_layout {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;;
    align-items: center;

}

.v-line {
    height: 34px;
    width: 1px;
    background-color: rgba(191, 106, 2, 1);
    /*margin: 0 10px;*/
}

.pray-circled-text {
    width: 34px;
    height: 34px;
    line-height: 34px;
    display: inline-block;
    text-align: center;
    color: rgba(191, 106, 2, 1); /* 设置字体颜色 */
    font-size: 24px; /* 设置字体大小 */
    font-weight: 510; /* 设置字体粗细 */
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 50%; /* 设置圆圈的边框半径 */
    /*padding: 3px 6px; !* 设置内边距 *!*/
    /*background-color: #00b578;*/
}

.outer-container {
    position: relative;
    overflow: hidden;
    margin-top: 24px;
}

.ability_container {

    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
}

.pray_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ability_title {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.image_container {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.title {
    margin-bottom: 8px;
    margin-top: 30px;
    font-size: 31px;
    color: white;
}

.font_title_1 {
    font-weight: 500;
    color: white;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 31px;
    /* text-align: center; */

}


.pray-title {
    margin-bottom: 8px;
    margin-top: 30px;
    font-size: 16px;
    color: white;
    width: 100%;
}

.pray-font_title_1 {
    width: 100%;
    font-weight: 500;
    color: white;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    /* text-align: center; */

}


.input-pray {
    placeholder-color: rgba(0, 0, 0, 0.3);
}

.pray-textarea-container {
    width: 100%; /* 宽度100%，根据容器自适应 */
    height: 90px; /* 高度自适应 */
    line-height: 1.5; /* 行高 */
    placeholder-color: rgba(0, 0, 0, 0.3);
    padding: 10px; /* 内边距 */
    box-sizing: border-box; /* 盒模型 */
    border: none;
}


.outer-container {
    position: relative;
    overflow: hidden;
    margin-top: 24px;
}

.ability_container {

    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
}

.pray_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ability_title {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.prayword_item {
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    width: calc(30%);
    border-radius: 4px;
    margin-top: 20px;
    color: rgba(151, 81, 2, 1);
    font-size: 16px;
    height: 32px;
    font-weight: 500;
    text-align: center;
    line-height: 32px;
    background-color: rgba(247, 247, 247, 1);
    margin: 5px 2px;
}

.page-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.spacehold {
    flex: 1;
}


.textarea {
    position: relative;
    top: 0;
    width: auto;
    height: 60px;
    /* background-color: red; */
}

.submitButton {
    margin: 15px 0;
    width: 100%;
    color: white;
}

.submitButton:disabled {
    background-color: #938F99; /* 禁用状态的按钮颜色 */
    color: #FFFFFF;
}


.h_line {
    background-color: rgba(151, 151, 151, 0.2);
    height: 1px;
    width: 100%;
    margin: 5px 2px;
}

.page-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.birthday_row {
    display: flex;
    flex-direction: row;
    height: 24px;
    background-color: rebeccapurple;
}

.spacehold {
    flex: 1;
}


.textarea {
    position: relative;
    top: 0;
    width: auto;
    height: 60px;
    /* background-color: red; */
}

.submitButton {
    margin: 15px 0;
    width: 100%;
    color: white;
}

.submitButton:disabled {
    background-color: #938F99; /* 禁用状态的按钮颜色 */
    color: #FFFFFF;
}



.pray-word {
    margin: 40px 20px;
    font-size: 16px;
    line-height: 36px;
}
.share-pray-word {
    margin: 40px 60px;
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
}

.spacer {
    flex: 1;
}

.donater {
    font-size: 16px;
    color: #BF6A02;
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
}
.share-donater {
    font-size: 16px;
    color: #BF6A02;
    /*margin-bottom: 40px;*/
    font-weight: 600;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}
.share-main{
    width: 100vw;
    height: 100vh;
    position: relative;
}

.imag-share-qr{
    /* margin-top: 60px; */
    width: 96px;
    height: 100px;
}

.share-button{

    position: absolute;
    bottom:30px;
    /*left:50%;*/
    z-index: 10;
}

.donate-item-row {
    /* margin: 20px 0px; */
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.donate-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 132px;
    width: 80px;
    justify-content: center;
    align-items: center;
    /* background-color: violet; */
    /* margin-right: 30px; */
}

.item-image-container {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

}


.item-image {
    width: 60%;
    height: 80%;
    object-fit: cover;
}

.item-check-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    object-fit: cover;
    bottom: 2px;
    right: 2px;
}


.donate-item-text {
    width: 90vw;
    margin-top: 3px;
    font-size: 12px;
    font-weight: 500;
    margin-left: 30px;

    text-align: left;
    color: white;
    white-space: nowrap;
    /*background-color: rebeccapurple;*/


}


