.check-notfound-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-image: url("https://package-app-storage.oss-cn-shenzhen.aliyuncs.com/h5/open_light_bg3.webp");
}


.notfound-container {
    width: 355px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.img-notfound {
    width: 180px;
    height: 170px;
    background-image: url("./not-found.png");
    background-size: cover;
}

.text-notfound {
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}


.check-btn {
    width: 100%;
    flex: 0;
    background-color: rgba(179, 38, 30, 1);
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 30px 20px;
    line-height: 48px;
    height: 48px;
    border: 1px solid rgba(191, 106, 2, 1);
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    color: white;

}




